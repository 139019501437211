import styled from 'styled-components';

import { gql } from '@apollo/client';
import { capitalize, orderBy, shuffle } from 'lodash';
import { NextPage } from 'next';
import Link from 'next/link';
import Router from 'next/router';
import { FunctionComponent } from 'react';
import { CodeSnippets } from '../components/code-snippets';
import { FOOTER_QUERY, Footer } from '../components/footer';
import { HEADER_QUERY, Header } from '../components/header';
import { HomeHero } from '../components/home-hero';
import {
  LATEST_UPDATES_QUERY,
  LatestUpdates,
} from '../components/latest-updates';
import { LogoCarousel } from '../components/logo-carousel';
import { Quotes } from '../components/quotes';
import { Col, ResponsiveContainer, Row } from '../components/responsive';
import { Angle } from '../components/ui/angle';
import { Button } from '../components/ui/button';
import { ContentStackClient } from '../lib/contentstack';
import { AllUseCase, Home, HomeQuotes, SysAsset } from '../types/contentstack';
import { IPageProps } from '../types/page';
import { getAssetUrl } from '../utils/contentstack';
import { mediaScreen } from '../utils/mediaScreen';
import { fetchConnectors, fetchConnectorsCount } from './api/connectors';

export const HomePage: NextPage<
  IPageProps<Home> & {
    all_use_case: AllUseCase;
    connectorCarouselData: { title: string; href: string; file: SysAsset }[];
    customerCarouselData: { file: SysAsset }[];
    connectorsCount: number;
  }
> = ({
  header,
  page,
  all_use_case,
  footer,
  latest_updates,
  connectorCarouselData,
  customerCarouselData,
  connectorsCount,
}) => {
  return (
    <>
      <Header
        pageTitle={page.title}
        pageDescription={page.description}
        {...header}
      />

      <HomeHero {...page.hero} use_cases={all_use_case?.items} />

      <ResponsiveContainer>
        <Row style={{ justifyContent: 'space-around' }}>
          {page?.numbers?.map((number) => {
            return (
              <Number xs={6} md={3} key={number?.title}>
                <img
                  src={getAssetUrl(number?.imageConnection)}
                  height="128"
                  alt="number"
                />

                <SectionTitle
                  dangerouslySetInnerHTML={{
                    __html: number?.title!?.replace(
                      '$connectorCount',
                      `${Math.ceil((connectorsCount + 10) / 10) * 10}+`
                    ),
                  }}
                />

                <SectionSubText
                  dangerouslySetInnerHTML={{ __html: number?.text! }}
                />
              </Number>
            );
          })}
        </Row>
      </ResponsiveContainer>

      {[1].map(() => {
        const [feature1, feature2, feature3] = page?.key_features ?? [];

        return (
          <WhiteBackgroundSection key="x">
            <LogoCarousel
              style={{ marginTop: '20px', marginBottom: '50px' }}
              direction="right"
              data={customerCarouselData}
            />

            <LogoCarousel
              height={50}
              direction="left"
              data={connectorCarouselData}
            />

            <div className="feature-titles">
              <ResponsiveContainer>
                <SectionApiSubTitle
                  style={{ color: 'black', marginBottom: '10px' }}
                >
                  {page?.pre_key_features?.subtitle}
                </SectionApiSubTitle>

                <SectionApiTitle style={{ color: 'black' }}>
                  {page?.pre_key_features?.title}
                </SectionApiTitle>

                <SectionSubText>{page?.pre_key_features?.text}</SectionSubText>
              </ResponsiveContainer>
            </div>

            <div className="feature feature1">
              <img
                src="/img/floor.svg"
                width="800"
                height="439"
                className="image"
                alt="floor"
              />

              <div style={{ flex: 1 }} />

              <ResponsiveContainer className="container">
                <Row>
                  <Col md={7} />
                  <Col md={5}>
                    <SectionSubTitle>{feature1?.title}</SectionSubTitle>

                    <SectionSubText>{feature1?.text}</SectionSubText>
                  </Col>
                </Row>
              </ResponsiveContainer>
            </div>

            <div className="feature feature2">
              <ResponsiveContainer className="container">
                <div className="text">
                  <SectionSubTitle>{feature2?.title}</SectionSubTitle>

                  <SectionSubText>{feature2?.text}</SectionSubText>
                </div>

                <img
                  src="/img/data-sharing.svg"
                  width="523"
                  height="418"
                  className="image"
                  alt="data sharing"
                />
              </ResponsiveContainer>

              <img
                src="/img/home-purple-glow-rectangle-1.svg"
                width="1039"
                height="595"
                className="glow"
              />
            </div>

            <div className="feature feature3">
              <ResponsiveContainer className="container">
                <img
                  src="/img/data-access.svg"
                  width="436"
                  height="580"
                  className="image"
                  alt="data access"
                />

                <div className="text">
                  <SectionSubTitle>{feature3?.title}</SectionSubTitle>

                  <SectionSubText>{feature3?.text}</SectionSubText>
                </div>
              </ResponsiveContainer>

              <img
                src="/img/home-purple-glow-rectangle-2.svg"
                width="1039"
                height="595"
                className="glow"
              />
            </div>

            <Angle position="bottom-left" />
          </WhiteBackgroundSection>
        );
      })}

      <WidthDelimiter id="api">
        <ResponsiveContainer>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Col md={12}>
              <SectionApiTitle>{page?.api?.title}</SectionApiTitle>
            </Col>

            <Col md={6}>
              <SectionApiSubTitle>{page?.api?.subtitle}</SectionApiSubTitle>
            </Col>

            <Col md={1} />
            <Col
              md={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                mType="primary"
                mColor="blue"
                style={{ marginTop: 20 }}
                onClick={() => Router.push(page?.api?.button?.href ?? '')}
              >
                {page?.api?.button?.title}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: 60 }}>
              <CodeSnippets />
            </Col>
          </Row>
        </ResponsiveContainer>
      </WidthDelimiter>

      <QuotesSection {...page?.quotes} all_use_case={all_use_case ?? []} />

      <UpdatesSection id="latest-updates">
        <LatestUpdates {...latest_updates} />
      </UpdatesSection>

      <Footer
        links={footer?.links}
        title={page?.footer?.title}
        text={page?.footer?.text}
        button={page?.footer?.button?.title}
        onButtonClick={() => Router.push(page?.footer?.button?.href ?? '')}
        withAngle={true}
      />
    </>
  );
};

const QuotesSection: FunctionComponent<
  HomeQuotes & { all_use_case: AllUseCase }
> = ({ title, subtitle, text, labeltitle, quotes, all_use_case }) => {
  return (
    <QuotesSectionContainer id="quotes">
      <ResponsiveContainer>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Col md={5}>
            <QuotesSectionSubTitle>{subtitle}</QuotesSectionSubTitle>
          </Col>
          <Col md={12}>
            <QuoteSectionTitle>{title}</QuoteSectionTitle>
          </Col>

          <Col md={2} />

          <Col md={8}>
            <SectionText style={{ marginBottom: 30 }}>{text}</SectionText>
          </Col>
          <Col
            md={5}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <SectionSubTitle>
              {capitalize(labeltitle?.toLowerCase())}
            </SectionSubTitle>
          </Col>

          <Col
            xs={12}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {all_use_case?.items?.map((industry) => {
              if (industry?.home_hero_only) {
                return null;
              }

              return (
                <Link href={industry?.url || ''} key={industry?.url!}>
                  <Button
                    mColor="teal"
                    mType="secondary"
                    style={{ margin: '15px 15px' }}
                  >
                    {industry?.short_title}
                  </Button>
                </Link>
              );
            })}
          </Col>
        </Row>
      </ResponsiveContainer>

      <WidthDelimiter>
        <Quotes
          quotes={
            quotes?.map((quote) => ({
              text: quote?.text ?? '',

              person: {
                name: quote?.person?.name ?? '',
                title: quote?.person?.title ?? '',
                picture: getAssetUrl(quote?.person?.pictureConnection),
              },
            })) ?? []
          }
        />
      </WidthDelimiter>
    </QuotesSectionContainer>
  );
};

export async function getServerSideProps() {
  try {
    const [{ data: data }, connectors, all_use_case, connectorsCount] =
      await Promise.all([
        ContentStackClient().query<IPageProps<Home>>({
          query: PAGE_QUERY,
        }),

        fetchConnectors(),
        fetchAllUseCases(),
        fetchConnectorsCount(),
      ]);

    const customerCarouselData =
      data?.page?.customers_logosConnection?.edges?.map((logo) => ({
        file: logo?.node,
      })) ?? [];

    const connectorCarouselData =
      connectors?.map((c) => {
        return {
          title: `${c?.title} Connector`,
          href: c?.url,
          file: c?.logoConnection?.edges?.[0]?.node,
        };
      }) ?? [];

    return {
      props: {
        ...data,

        all_use_case: {
          ...all_use_case,
          items: orderBy(all_use_case?.items, 'position'),
        },

        customerCarouselData: shuffle(customerCarouselData).slice(0, 50),
        connectorCarouselData: shuffle(connectorCarouselData).slice(0, 50),
        connectorsCount,

        page: {
          ...data.page,
          customers_logosConnection: null,
        },
      },
    };
  } catch (e: any) {
    console.log(JSON.stringify(e?.networkError?.result, null, 2));
    return { props: {} };
  }
}

export async function fetchAllUseCases() {
  const result = await ContentStackClient().query<{
    all_use_case: AllUseCase;
  }>({
    query: gql`
      query useCasesQuery {
        all_use_case {
          items {
            short_title
            short_title_color
            url
            position
            home_hero_only
            hero {
              button {
                title
                href
              }
              imageConnection {
                edges {
                  node {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  return result?.data?.all_use_case;
}

const PAGE_QUERY = gql`
  query homeQuery {
    ${HEADER_QUERY}
    ${FOOTER_QUERY}
    ${LATEST_UPDATES_QUERY}

    page: home(uid: "blt8f9afa3d00677fea") {
      title
      description
      hero {
        title
        text
        button {
          title
          href
        }
      }
      numbers {
        title
        text
        imageConnection {
          edges {
            node {
              url
            }
          }
        }
      }
      customers_logosConnection(limit: 50) {
        edges {
          node {
            url
            dimension {
              width
              height
            }
          }
        }
      }
      pre_key_features {
        title
        subtitle
        text
      }
      key_features {
        title
        text
      }
      api {
        title
        subtitle
        text
        button {
          title
          href
        }
      }
      quotes {
        title
        subtitle
        text
        labeltitle
        quotes {
          text
          person {
            name
            title
            pictureConnection {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
      footer {
        title
        text
        button {
          title
          href
        }
      }
    }
  }
`;

const WidthDelimiter = styled.div`
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
`;

const SectionApiTitle = styled.h2`
  font-size: 120px;
  line-height: 1.2;
  color: white;
  font-weight: 700;
  margin: 0;
  text-align: center;

  ${mediaScreen('xs', 'md')} {
    font-size: 65px;
  }
`;

const SectionApiSubTitle = styled.h3`
  font-size: 44px;
  margin-top: 0;
  line-height: 1.29;
  color: white;
  font-weight: 400;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 20px;
    margin-bottom: 16px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 24px;
  }
`;

const QuoteSectionTitle = styled.h2`
  font-size: 80px;
  line-height: 1.2;
  color: white;
  font-weight: 700;
  margin: 0 0 24px 0;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 40px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 70px;
  }
`;

const QuotesSectionSubTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 44px;
  line-height: 1.29;
  color: white;
  font-weight: 400;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 20px;
    margin-bottom: 16px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 24px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 50px;
  line-height: 1.2;
  color: white;
  font-weight: bold;
  margin-top: 0;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 29px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 40px;
  }
`;

const SectionSubTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;
  line-height: 1.29;
  color: white;
  font-weight: 500;

  ${mediaScreen('xs', 'sm')} {
    font-size: 20px;
    margin-bottom: 16px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 24px;
  }
`;

const Number = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 60px;

  img {
    height: 90px;
    margin-bottom: 35px;
  }

  h2 {
    margin-bottom: 10px;
  }

  ${mediaScreen('xs', 'md')} {
    img {
      height: 68px;
    }
  }
`;

const SectionText = styled.p`
  color: white;
  font-size: 22px;
  line-height: 30px;
  text-align: center;

  ${mediaScreen('xs', 'sm')} {
    font-size: 17px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 19px;
  }

  a,
  a:visited {
    color: white !important;
    text-decoration: underline;
  }
`;

const SectionSubText = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.5;

  ${mediaScreen('xs', 'sm')} {
    font-size: 14px;
  }
`;

const WhiteBackgroundSection = styled(WidthDelimiter)`
  position: relative;
  overflow: hidden;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 31.01%,
      rgba(146, 137, 226, 0.2) 82.54%,
      rgba(146, 137, 226, 0.2) 93.11%
    ),
    #ffffff;
  margin-top: 0;
  margin-bottom: 100px;

  ${mediaScreen('xs', 'md')} {
    margin-bottom: 60px;
  }

  .feature-titles {
    margin-bottom: 100px;
    margin-top: 100px;

    p {
      margin-top: 20px;
      text-align: center;
    }
  }

  .feature {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;

    &:first-of-type {
      align-items: flex-end;
    }

    &:last-of-type {
      align-items: flex-start;
    }

    .container {
      position: absolute;
    }

    label {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1.33;
      color: ${({ theme }) => theme.colors.eb1};
      font-weight: bold;
      margin-bottom: 32px;
      display: block;
    }

    &.feature1 {
      .image {
        width: 100%;
        max-width: 100%;
        z-index: 5;
        margin-bottom: -250px;
        margin-left: -40%;
        position: relative;
        pointer-events: none;
      }
    }

    &.feature2 {
      margin-top: 150px;

      .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        max-width: 400px;
      }

      .image {
        width: 60%;
        margin-left: 10%;
        margin-right: -10%;
        z-index: 3;
        pointer-events: none;
      }

      .glow {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 30%;
        top: 75%;
        pointer-events: none;

        ${mediaScreen('xl')} {
          left: 50%;
        }
      }
    }

    &.feature3 {
      margin-bottom: 250px;

      .image {
        width: 42%;
        margin-left: 0%;
        margin-right: 15%;
        position: relative;
        z-index: 3;
        pointer-events: none;
      }

      .text {
        max-width: 365px;
      }

      .container {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 110px;
      }

      .glow {
        position: absolute;
        pointer-events: none;
        bottom: 0;
        left: 0;
        right: 50%;
        top: 0;
      }
    }
  }

  ${mediaScreen('xs', 'md')} {
    margin-top: 0;
    margin-bottom: 20%;

    h3,
    p {
      text-align: center;
    }

    .feature-titles {
      margin-bottom: 80px;
      margin-top: 80px;
    }

    .feature .container {
      position: relative !important;
    }

    .feature1 {
      flex-direction: column-reverse;
      margin-top: 10% !important;

      .image {
        margin-top: 45px;
        width: 180% !important;
        margin-right: -15% !important;
        max-width: 180% !important;
        margin-bottom: -90px !important;
      }

      ${SectionTitle} {
        display: none;
      }
    }

    .feature2 {
      .container {
        flex-direction: column;
      }

      .text {
        max-width: 100% !important;
      }

      .image {
        width: 110% !important;
        max-width: 110% !important;
        align-self: flex-end;
        margin-top: 60px;
        margin-left: -5% !important;
        margin-right: auto !important;
      }
    }

    .feature3 {
      margin-top: -40px;
      margin-bottom: 0 !important;

      .container {
        flex-direction: column-reverse;
      }

      .text {
        max-width: 100% !important;
      }

      .image {
        margin-top: 65px;
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 50px !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  ${SectionTitle}, ${SectionSubTitle}, ${SectionSubText} {
    color: ${({ theme }) => theme.colors.slate1};
  }

  ${mediaScreen('md')} {
    ${SectionSubTitle} {
      font-size: 38px;
    }

    ${SectionSubText} {
      font-size: 22px;
    }
  }
`;

const QuotesSectionContainer = styled.div`
  margin-top: 150px;

  ${mediaScreen('xs', 'md')} {
    margin-top: 50px;
  }
`;

const UpdatesSection = styled.div``;

export default HomePage;
