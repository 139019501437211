import { DateTime } from 'luxon';

export function dateAndTime(date?: Date, format?: 'short' | 'long') {
  if (!date) {
    return null;
  }

  const string = DateTime.fromJSDate(new Date(date)).toLocaleString({
    month: format ?? 'short',
    day: '2-digit',
    year: 'numeric',
    locale: 'en',
  });

  return string;
}
