import { FunctionComponent, InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';

export const TextInput: FunctionComponent<TTextInput> = ({
  label,
  ...props
}) => {
  const ref = useRef<any>(null);

  return (
    <Container className="text-input" onClick={() => ref.current?.focus()}>
      <label>{label}</label>

      <input {...props} ref={ref} />
    </Container>
  );
};

const Container = styled.div`
  background: #262f40;
  width: 100%;
  padding: 8px 16px;
  cursor: text;

  label {
    display: block;
    color: #8092b2;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    margin-bottom: 8px;
    pointer-events: none;
    text-align: left;
  }

  input {
    appearance: none;
    width: 100%;
    background: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    outline: none;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
      font-weight: 300;
    }
  }
`;

type TTextInput = InputHTMLAttributes<any> & {
  label: string;
};
