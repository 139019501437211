export function classnames(classes: { [key: string]: boolean }) {
  const classNames: string[] = [];

  Object.keys(classes).forEach((className) => {
    if (!!classes[className]) {
      classNames.push(className);
    }
  });

  return classNames.join(' ');
}
