import { ApolloClient, InMemoryCache } from '@apollo/client';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export const gqlCache = new InMemoryCache();
export const restCache: { [key: string]: any } = {};

const environment = process?.env?.CONTENTSTACK_API_URI?.match(
  /environment\=(\w+)$/
)?.[1] as 'development' | 'production';

const api_key =
  process?.env?.CONTENTSTACK_API_URI?.match(/\/stacks\/(.*)\?/)?.[1];

const access_token = process?.env?.CONTENTSTACK_DELIVERY_TOKEN as string;

export const ContentStackClient = () =>
  new ApolloClient({
    cache: environment !== 'development' ? gqlCache : new InMemoryCache(),
    uri: process.env.CONTENTSTACK_API_URI,
    headers: {
      access_token,
    },
  });

export const ContentStackRESTRequest = (
  config: AxiosRequestConfig
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    const cacheKey = JSON.stringify(config);

    if (restCache[cacheKey] && environment !== 'development') {
      return resolve(restCache[cacheKey]);
    }

    axios({
      ...config,
      url: 'https://cdn.contentstack.io/v3' + config.url,
      method: 'GET',
      params: {
        environment,
        ...config?.params,
      },
      headers: {
        access_token,
        api_key,
      },
    })
      .then((res) => {
        restCache[cacheKey] = res;
        resolve(res);
      })
      .catch(reject);
  });
};
