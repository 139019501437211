import { useWindowSize } from '@react-hook/window-size/throttled';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Slider from 'react-slick';
import styled from 'styled-components';
import { getTheme } from '../../theme/getTheme';
import { mediaScreen } from '../../utils/mediaScreen';
import { Icon } from '../ui/icon';
import { OptimizedImage } from '../ui/optimizedImage';

export const Quotes: FunctionComponent<IQuotes> = ({ quotes }) => {
  const [windowWidth] = useWindowSize();
  const slider = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ticked, setTicked] = useState(false);

  const isMobile =
    !!ticked && !!windowWidth && windowWidth < getTheme().breakpoints.md;

  const isSingleSlide = quotes?.length === 1;

  useEffect(() => {
    setTicked(true);
  }, []);

  return (
    <Container>
      <Slider
        ref={slider}
        infinite={false}
        slidesToShow={1}
        centerMode={!isMobile}
        centerPadding="17%"
        slidesToScroll={1}
        dots={isMobileOnly}
        arrows={false}
        beforeChange={(_, i) => setCurrentSlide(Math.round(i))}
      >
        {quotes.map(({ text, person }, index) => {
          const isActive = currentSlide === index;
          const isFirst = index === 0;
          const isLast = index === quotes.length - 1;
          const isMiddle = !isFirst && !isLast;

          return (
            <Slide key={text} className="slide">
              <div className="content">
                {!isMobile && (
                  <img
                    src="/img/quote.svg"
                    width={45}
                    height={41}
                    className="quote-icon"
                  />
                )}

                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: text }}
                />

                <div className="person">
                  <div className="picture">
                    <OptimizedImage
                      alt={person?.name}
                      src={person?.picture}
                      width={70}
                      height={70}
                    />
                  </div>
                  <div className="info">
                    <h3>{person?.name}</h3>
                    <p>{person?.title}</p>
                  </div>
                </div>
              </div>

              {isActive && !isMobile && !isSingleSlide && (
                <>
                  {(isMiddle || isLast) && (
                    <Arrow
                      position="left"
                      onClick={() => slider?.current?.slickPrev()}
                    >
                      <Icon name="arrow-left" />
                    </Arrow>
                  )}

                  {(isFirst || isMiddle) && (
                    <Arrow
                      position="right"
                      onClick={() => slider?.current?.slickNext()}
                    >
                      <Icon name="arrow-right" />
                    </Arrow>
                  )}
                </>
              )}
            </Slide>
          );
        })}
      </Slider>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 40px;

  .slick-dots {
    margin-top: 20px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: ${({ theme }) => theme.colors.gray};
      margin: 10px;
      cursor: pointer;

      button {
        width: 100%;
        height: 100%;
        appearance: none;
        background: none;
        font-size: 1px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.gray};
      }

      &.slick-active {
        background: ${({ theme }) => theme.colors.teal};

        button {
          color: ${({ theme }) => theme.colors.teal};
        }
      }
    }
  }
`;

const Arrow = styled.div<{ position: 'left' | 'right' }>`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 250px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  background: ${(props) => props.theme.colors.teal};
  cursor: pointer;
  z-index: 10;
  ${({ position }) => `${position}: 0;`}

  &:hover {
    background: #429e8e;
  }
`;

const Slide = styled.div`
  padding: 0 35px;
  position: relative;

  .content {
    background: ${(props) => props.theme.colors.slate2};
    border-radius: 16px;
    padding: 50px 70px;

    .text {
      font-size: 28px;
      line-height: 36px;
      margin-top: 40px;
      min-height: 260px;
      margin-bottom: 50px;

      strong {
        color: ${(props) => props.theme.colors.teal};
      }
    }

    .person {
      display: flex;

      .picture {
        width: 70px;
        height: 70px;
        margin-right: 20px;

        img {
          border-radius: 10%;
        }
      }

      .info {
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1;

        h3 {
          font-weight: bold;
          margin: 0;
          margin-bottom: 5px;
          font-size: 22px;
          line-height: 28px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          margin: 0;
        }
      }
    }
  }

  ${mediaScreen('xs', 'md')} {
    padding: 0 20px;

    .content {
      display: flex;
      flex-direction: column-reverse;
      padding: 25px;

      .text {
        font-size: 16px;
        line-height: 24px;
        min-height: 0;
        margin-bottom: 0;
        margin-top: 24px;
        font-weight: bold;

        &::before {
          content: '“';
        }

        &::after {
          content: '”';
        }
      }

      .person {
        flex-direction: column;

        .picture {
          width: 50px;
          height: 50px;
        }

        .info {
          display: block;
          margin-top: 16px;

          h3,
          p {
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
    }
  }
`;

interface IQuotes {
  quotes: {
    text: string;
    person: {
      name: string;
      title: string;
      picture: string;
    };
  }[];
}
