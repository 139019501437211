import useInterval from '@use-it/interval';
import Link from 'next/link';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AllUseCase, HomeHero as HomeHeroType } from '../../types/contentstack';
import { classnames } from '../../utils/classnames';
import { getAssetUrl } from '../../utils/contentstack';
import { mediaScreen } from '../../utils/mediaScreen';
import { ResponsiveContainer } from '../responsive';
import { Button } from '../ui/button';
import { OptimizedImage } from '../ui/optimizedImage';

const SLIDE_INTERVAL = 3000;
const TRANSITION_DURATION = 600;

export const HomeHero: FunctionComponent<
  HomeHeroType & { use_cases: AllUseCase['items'] }
> = ({ title, text, button, use_cases }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const useCase = use_cases?.[activeIndex];

  useInterval(() => {
    if (activeIndex === (use_cases?.length || 0) - 1) {
      return setActiveIndex(0);
    }

    setActiveIndex(activeIndex + 1);
  }, SLIDE_INTERVAL);

  return (
    <Hero id="home-hero">
      <Carousel>
        {use_cases?.map((uc, i) => {
          return (
            <Slide key={i} className={i === activeIndex ? 'active' : ''}>
              <OptimizedImage
                priority={i === 0}
                src={getAssetUrl(uc?.hero?.imageConnection)}
                alt={'hero image ' + uc?.short_title}
                fill={true}
              />
            </Slide>
          );
        })}
      </Carousel>

      <HeroContent id="hero-content">
        <ResponsiveContainer>
          <HeroTitle>
            <span dangerouslySetInnerHTML={{ __html: title! }} />
            <span className="space">&nbsp;</span>

            <CaseTitle
              title={useCase?.short_title!}
              color={useCase?.short_title_color!}
            />
          </HeroTitle>

          <HeroText dangerouslySetInnerHTML={{ __html: text! }} />

          <Link
            href={
              useCase?.home_hero_only
                ? useCase?.hero?.button?.href!
                : useCase?.url ?? ''
            }
          >
            <Button mColor="teal">
              {useCase?.home_hero_only
                ? useCase?.hero?.button?.title
                : button?.title}
            </Button>
          </Link>
        </ResponsiveContainer>
      </HeroContent>
    </Hero>
  );
};

const CaseTitle: FunctionComponent<{ title: string; color?: string }> = ({
  title,
  color,
}) => {
  const [prevTitle, setPrevTitle] = useState(title);
  const [prevColor, setPrevColor] = useState(color);

  useEffect(() => {
    setTimeout(() => {
      setPrevTitle(title);
      setPrevColor(color);
    }, TRANSITION_DURATION + 100);
  }, [title]);

  return (
    <span className="case-title">
      <strong
        className={classnames({ fadeInDown: title !== prevTitle })}
        style={{ color }}
      >
        {title}
      </strong>

      {prevTitle !== title && (
        <strong
          className={classnames({ fadeOutDown: true })}
          style={{ color: prevColor }}
        >
          {prevTitle}
        </strong>
      )}
    </span>
  );
};

const Carousel = styled.div`
  position: absolute;
  max-width: 1600px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  overflow: hidden;
`;

const Slide = styled.div`
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity 1s ease;

  .image {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
    }
  }

  &:not(:first-of-type) {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.active {
    opacity: 1;
  }
`;

const Hero = styled.div`
  width: 100%;
  margin-top: 80px;
  position: relative;
  margin-bottom: 50px;
`;

const HeroContent = styled.div`
  padding: 250px 0;
  position: relative;
  z-index: 1;
  width: 100%;
  background: rgba(25, 31, 44, 0.7);

  ${mediaScreen('xs', 'sm')} {
    padding: 100px 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const HeroTitle = styled.h1`
  color: white;
  font-size: 65px;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;

  .case-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.teal};
    flex: 1;

    strong {
      animation-duration: ${TRANSITION_DURATION}ms;

      &.fadeInDown {
        position: relative;
      }

      &.fadeOutDown {
        position: absolute;
      }
    }
  }

  ${mediaScreen('xs', 'sm')} {
    font-size: 42px;
    font-weight: 500;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

    .case-title {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    .space {
      display: none;
    }
  }
`;

const HeroText = styled.p`
  color: white;
  font-size: 22px;
  line-height: 32px;
  margin-top: 24px;
  max-width: 690px;
  font-weight: 400;
  margin-bottom: 32px;

  ${mediaScreen('xs', 'sm')} {
    font-size: 17px;
    line-height: 24px;
    margin-top: 24px;
    text-align: center;
  }
`;
