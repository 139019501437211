import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { classnames } from '../../utils/classnames';
import { mediaScreen } from '../../utils/mediaScreen';

export const MODAL_TRANSITION_DURATION = 350;

const ForwardableModal: ForwardRefRenderFunction<any, IModalProps> = (
  { isOpen, onRequestClose, style, children },
  ref
) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      return;
    }

    setTimeout(() => setIsVisible(false), MODAL_TRANSITION_DURATION);
  }, [isOpen]);

  if (!isVisible) {
    return null;
  }

  return (
    <Container
      className={classnames({
        modal: true,
        fadeIn: isOpen,
        fadeOut: !isOpen,
      })}
    >
      <Backdrop onClick={onRequestClose} className="backdrop" />

      <Inner className="inner">
        <Content
          ref={ref}
          style={style}
          className={classnames({
            content: true,
            slideUp: isOpen,
            slideDown: !isOpen,
          })}
        >
          {children}
        </Content>
      </Inner>
    </Container>
  );
};

export const Modal = forwardRef(ForwardableModal);

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  overflow: hidden;
  animation-duration: ${MODAL_TRANSITION_DURATION}ms;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2010;
  position: absolute;
  background: rgba(77, 95, 128, 0.6);
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  z-index: 2020;
  overflow: hidden;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.slate1};
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.26);
  animation-duration: ${MODAL_TRANSITION_DURATION}ms;

  ${mediaScreen('xs', 'md')} {
    margin: 0 15px;
  }
`;

export interface IModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  style?: CSSProperties;
  children?: ReactNode;
}
