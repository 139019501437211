import { Tooltip } from '@mui/material';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';
import { SysAsset } from '../../types/contentstack';
import { OptimizedImage } from '../ui/optimizedImage';

export const LogoCarousel: FunctionComponent<{
  direction?: 'left' | 'right';
  style?: CSSProperties;
  title?: string;
  height?: number;
  data?: { href?: string; title?: string; file: SysAsset }[];
}> = ({ title, style, data = [], direction = 'left', height }) => {
  const router = useRouter();
  const [, update] = useState(Date.now());
  const [playState, setPlayState] = useState<'running' | 'paused'>('running');
  const width = useRef<number>(0);

  const animationName = useMemo(() => {
    if (!width?.current) {
      return keyframes``;
    }

    const offset = -width?.current! / 2;

    if (direction === 'left') {
      return keyframes`
        0% { transform: translateX(0); }
        50% { transform: translateX(${offset}px); }
        100% { transform: translateX(0); }
      `;
    }

    return keyframes`
      0% { transform: translateX(${offset}px); }
      50% { transform: translateX(0); }
      100% { transform: translateX(${offset}px); }
    `;
  }, [width?.current, direction]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', ...style }}
      title={title}
    >
      <Carousel
        style={{ height, opacity: width?.current ? 1 : 0 }}
        animationName={animationName}
        animationDuration="120s"
      >
        <div
          className="track"
          style={{ animationPlayState: playState }}
          ref={(ref) => {
            if (!width?.current) {
              width.current = ref?.scrollWidth!;
              update(Date.now());
            }
          }}
        >
          {data.map(({ title, href, file }, i) => (
            <Tooltip
              title={title}
              key={file?.url! + i}
              disableHoverListener={!title}
              arrow={true}
              placement="bottom"
            >
              <div
                className="logo"
                onClick={() => router.push(href!)}
                style={{
                  cursor: 'pointer',
                  pointerEvents: title ? 'all' : 'none',
                }}
                onMouseEnter={() => setPlayState('paused')}
                onMouseLeave={() => setPlayState('running')}
              >
                <OptimizedImage
                  alt={'logo' + i}
                  src={file?.url!}
                  height={file?.dimension?.height!}
                  width={file?.dimension?.width!}
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </Carousel>
    </div>
  );
};

const Carousel = styled.div<{ animationName?: any; animationDuration?: any }>`
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100%;
    position: absolute;
    width: 25%;
    z-index: 2;
    pointer-events: none;
  }

  &::after {
    right: -2px;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: -2px;
    top: 0;
  }

  .track {
    height: 100%;
    display: flex;
    position: relative;
    animation: ${(props) => props.animationName}
      ${(props) => props.animationDuration} linear infinite;
  }

  .logo {
    height: 100%;
    padding: 0 20px;

    > div {
      height: 100%;
    }

    img {
      height: 100%;
      max-width: none;
      width: auto;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
