import { useWindowSize } from '@react-hook/window-size/throttled';
import { sortBy } from 'lodash';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { getTheme } from '../../theme/getTheme';
import {
  LatestUpdates as ILatestUpdates,
  LatestUpdatesLeadModal,
} from '../../types/contentstack';
import { getAssetUrl } from '../../utils/contentstack';
import { dateAndTime } from '../../utils/dateAndTime';
import { mediaScreen } from '../../utils/mediaScreen';
import { Modal } from '../modal';
import { ResponsiveContainer } from '../responsive';
import { Button } from '../ui/button';
import { Icon } from '../ui/icon';
import { TextInput } from '../ui/textInput';

const cookies = new Cookies();

export const LatestUpdates: FunctionComponent<ILatestUpdates> = ({
  title,
  updates,
  lead_modal,
}) => {
  const { breakpoints } = getTheme();
  const [windowWidth] = useWindowSize();

  const slider = useRef<Slider>(null);
  const [ticked, setTicked] = useState(false);
  const [lockedURL, setLockedURL] = useState('');

  const sortedUpdates = sortBy(updates, (u) => new Date(u?.date), '').reverse();

  let slidesToShow = 3.2;

  if (ticked && !!windowWidth) {
    if (windowWidth < breakpoints.xl) {
      slidesToShow = 2.05;
    }

    if (windowWidth < breakpoints.md) {
      slidesToShow = 1;
    }
  }

  useEffect(() => {
    setTicked(true);
  }, []);

  return (
    <WidthDelimiter>
      <SliderContainer>
        <ResponsiveContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Title style={{ display: 'flex', alignSelf: 'center' }}>
              {title}
            </Title>
          </div>
        </ResponsiveContainer>

        <Slider
          ref={slider}
          autoplay={true}
          autoplaySpeed={1500}
          infinite={true}
          slidesToShow={slidesToShow}
          centerMode={true}
          centerPadding="10%"
          slidesToScroll={1}
          initialSlide={1}
          dots={false}
          arrows={false}
        >
          {sortedUpdates?.map((update) => {
            const { title, url, logoConnection, fileConnection, locked, date } =
              update ?? {};

            const logo = getAssetUrl(logoConnection);
            const file = getAssetUrl(fileConnection);

            return (
              <Slide key={title} className="slide">
                <div
                  className="content"
                  onClick={() => {
                    if (locked && !cookies.get('lead')) {
                      return setLockedURL(file || url || '');
                    }

                    window.open(file || url || '', '_blank');
                  }}
                >
                  <p
                    className="type"
                    style={{
                      color: !!file ? 'white' : 'transparent',
                    }}
                  >
                    WHITEPAPER
                  </p>

                  {locked && <Icon name="lock" />}

                  <img className="logo" src={logo} height={35} width={120} />

                  <p className="title">{title}</p>

                  <div style={{ display: 'flex' }}>
                    <span className="date">{dateAndTime(date, 'long')}</span>

                    <Icon name={!!file ? 'pdf' : 'external-link'} size={22} />
                  </div>
                </div>
              </Slide>
            );
          })}
        </Slider>
      </SliderContainer>

      <LeadModal
        {...lead_modal}
        url={lockedURL}
        onRequestClose={() => setLockedURL('')}
      />
    </WidthDelimiter>
  );
};

const LeadModal: FunctionComponent<
  LatestUpdatesLeadModal & {
    url?: string;
    onRequestClose: () => void;
  }
> = ({ url, onRequestClose, title, email_input_label, button }) => {
  const [email, setEmail] = useState<string>();

  function onSubmit() {
    cookies.set('lead', 'true');

    const payload = {
      name: email,
      customFields: [
        {
          id: ELeadFormField.EMAIL,
          value: email,
        },
        {
          id: ELeadFormField.SOURCE,
          value: 'LATEST_UPDATES',
        },
        {
          id: ELeadFormField.OBJECT,
          value: url,
        },
      ],
    };

    fetch('/api/lead', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'content-type': 'application/json',
      },
    });

    setTimeout(() => onRequestClose(), 500);
  }

  return (
    <Modal isOpen={!!url} onRequestClose={onRequestClose}>
      <ModalContent>
        <h2>{title}</h2>

        <form
          id="leadForm"
          onSubmit={onSubmit}
          method="get"
          target="_blank"
          action={url}
        >
          <TextInput
            required={true}
            label={email_input_label ?? ''}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button type="submit" mColor="teal">
            {button}
          </Button>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const LATEST_UPDATES_QUERY = `
  latest_updates(uid: "bltd7860018df5a81aa") {
    title
    updates {
      title
      url
      locked
      date
      logoConnection {
        edges {
          node {
            url
          }
        }
      }
      fileConnection {
        edges {
          node {
            url
          }
        }
      }
    }
    lead_modal {
      title
      email_input_label
      button
    }
  }
`;

const WidthDelimiter = styled.div`
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 150px;
  margin-bottom: 150px;

  .slick-dots {
    margin-top: 20px;
    margin-bottom: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: ${({ theme }) => theme.colors.gray};
      margin: 10px;
      cursor: pointer;

      button {
        width: 100%;
        height: 100%;
        appearance: none;
        background: none;
        font-size: 1px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.gray};
      }

      &.slick-active {
        background: ${({ theme }) => theme.colors.teal};

        button {
          color: ${({ theme }) => theme.colors.teal};
        }
      }
    }
  }
`;

const Slide = styled.div`
  padding: 0 25px;

  .content {
    background: ${(props) => props.theme.colors.slate2};
    border-radius: 16px;
    padding: 25px 35px;
    border: 2px solid transparent;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: ${(props) => props.theme.colors.teal};
    }

    .icon-lock {
      position: absolute;
      top: 25px;
      right: 35px;
      pointer-events: none;
      user-select: none;
    }

    .type {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.2px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .logo {
      height: 35px;
      width: auto;
      filter: brightness(0) invert(1);
    }

    .title {
      font-size: 22px;
      line-height: 32px;
      margin-top: 35px;
      height: 150px;
      margin-bottom: 50px;
      font-weight: bold;
    }

    .date {
      font-size: 16px;
      line-height: 24px;
      flex: 1;
    }
  }

  ${mediaScreen('xs', 'md')} {
    padding: 0 12px;

    .content {
      padding: 25px;

      .icon-lock {
        right: 25px;
      }

      .title {
        font-size: 16px;
        line-height: 24px;
        min-height: 0;
        height: 1f0px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }
`;

const Title = styled.h2`
  font-size: 80px;
  flex: 1;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 0;

  ${mediaScreen('xs', 'sm')} {
    font-size: 36px;
  }

  ${mediaScreen('sm', 'md')} {
    font-size: 65px;
  }
`;

const ModalContent = styled.div`
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    font-size: 38px;
    line-height: 44px;
    text-align: center;
    max-width: 483px;
    margin-bottom: 48px;
    margin-top: 0;
  }

  form,
  .text-input,
  .alert,
  button {
    width: 100%;
    max-width: 400px;
  }

  .text-input {
    margin-bottom: 25px;
  }

  .alert {
    margin-top: 25px;
  }

  ${mediaScreen('xs', 'md')} {
    padding: 25px 15px;

    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 30px;
    }

    .alert {
      padding: 10px 0;

      > div {
        justify-content: center;
        width: 100%;
      }
    }

    .icon-checkmark {
      display: none;
    }
  }
`;

enum ELeadFormField {
  EMAIL = '128a689a-ee81-4ca0-9370-1c256286be59',
  SOURCE = '0a1fada1-5ff1-4edc-afe0-3d1459ccf5ec',
  OBJECT = '1edfbdbc-7caf-408a-a53a-c9362a47c919',
}
